import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import AvatarIcon from '@mui/icons-material/AccountCircle';
import { DialogContext } from '../../../contexts/DialogContext';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Remove';
import axios from '../../../api/axios';
import { CashbackMethod } from '../../../types';

function SettingsDialog() {

  const dialogContext = useContext(DialogContext);

  const [avatar, setAvatar] = useState("");
  const [cashbackMethods, setCashbackMethods] = useState<CashbackMethod[]>([]);
  const [newCashbackMethod, setNewCashbackMethod] = useState<CashbackMethod>({
    id: "",
    provider: "",
    userIdentifier: "",
  })
  const [error, setError] = useState("");

  useEffect(() => {
    if(!dialogContext.settings) return;
    getCashbackMethods();
  }, [dialogContext.settings])
  
  async function getCashbackMethods() {
    try {
      const response = await axios.get("/api/cashback/methods");
      setCashbackMethods(response.data.methods);
    } catch (err: any) {
      console.warn(err);
    }
  }

  async function handleAddCashbackMethod() {
    try {
      const { provider, userIdentifier } = newCashbackMethod;
      if(!provider || !userIdentifier) {
        setError("Please fill in all fields");
        return;
      }
      const response = await axios.post("/api/cashback/methods", {
        provider,
        userIdentifier,
      });
      setCashbackMethods([...cashbackMethods, response.data.method]);
      setNewCashbackMethod({
        id: "",
        provider: "",
        userIdentifier: "",
      });
    } catch (err: any) {
      console.warn(err);
      setError(err.response.data.error);
    }
  }

  function handleDeleteCashbackMethod(id: string) {
    return async () => {
      try {
        await axios.delete(`/api/cashback/methods/${id}`);
        setCashbackMethods(cashbackMethods.filter((method) => method.id !== id));
      } catch (err: any) {
        console.error(err);
        setError(err.response.data.error);
      }
    }
  }

  function closeDialog() {
    dialogContext.closeDialog();
  }

  return (
    <Dialog open={dialogContext.settings} onClose={closeDialog}>
      <DialogContent style={{ minWidth: 450, }}>
        <Grid direction='column' spacing={'4px'}>
          <Grid item>
            <Typography variant='h4'>Settings</Typography>
          </Grid>
          {
            error &&
            <Grid item>
              <Typography variant='body1' color='error' textAlign='center' style={{ marginTop: 10 }}>{error}</Typography>
            </Grid>
          }
          <Grid item>
            <Grid container justifyContent='center'>
              {
                avatar?
                <Avatar src={avatar} style={{ width: 80, height: 80, borderRadius: '25%' }} />
                :
                <AvatarIcon style={{ width: 80, height: 80, borderRadius: '25%' }} />
              }
            </Grid>
          </Grid>
          <Grid item style={{ padding: '10px 0px' }}>
            <Typography variant='h5'>Cashback Methods</Typography>
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={'4px'}>
            {
              cashbackMethods.map((method) => (
                <Grid container key={method.id} alignItems='center'>
                  <Grid item xs={4}>
                    <Typography variant='body1'>{method.provider.toUpperCase()}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>{method.userIdentifier}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant='text' onClick={handleDeleteCashbackMethod(method.id)}><DeleteIcon /></Button>
                  </Grid>
                </Grid>
              ))
            }
            <Grid container spacing={'2px'}>
              <Grid item xs={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='cashback-method-provider-label'>Provider</InputLabel>
                  <Select label="Provider" labelId='cashback-method-provider-label' fullWidth value={newCashbackMethod.provider} onChange={(e) => setNewCashbackMethod({ ...newCashbackMethod, provider: e.target.value as string })}>
                    <MenuItem value="" disabled>Select Provider</MenuItem>
                    <MenuItem value="venmo">Venmo</MenuItem>
                    <MenuItem value="paypal">PayPal</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label='Provider Username/Email'
                  value={newCashbackMethod.userIdentifier}
                  onChange={(e) => setNewCashbackMethod({ ...newCashbackMethod, userIdentifier: e.target.value })}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant='text' onClick={handleAddCashbackMethod}><AddIcon /></Button>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant='text' onClick={closeDialog}>Close</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SettingsDialog