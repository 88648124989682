import { FunctionComponent as FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

interface ScrollbarProps {
  className?: string;
  children: ReactNode | any;
}

const Scrollbar: FC<ScrollbarProps> = ({ className, children, ...rest }) => {
  const theme = useTheme() as Theme;

  return <Scrollbars
    autoHide
    renderThumbVertical={() => {
      return (
        <Box
          sx={{
            width: 5,
            background: `${theme.colors.alpha.black[10]}`,
            borderRadius: `${theme.general.borderRadiusLg}`,
            transition: `${theme.transitions.create(['background'])}`,

            '&:hover': {
              background: `${theme.colors.alpha.black[30]}`
            }
          }}
        />
      );
    }}
    {...rest}
  >
    {children}
  </Scrollbars>;
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
