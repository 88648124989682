import React from "react";
import { useLocation } from 'react-router-dom';

const EventComparisonHeader = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="flex flex-col items-center justify-center p-4 text-center">
      <div className="flex items-center mb-4">
        <h1 className={`text-3xl md:text-5xl lg:text-6xl font-semibold ${isHomePage ? "text-white" : "text-black"}`}>Search.</h1>
        <h1 className={`text-3xl md:text-5xl lg:text-6xl font-semibold ${isHomePage ? "text-white" : "text-black"}`}>Compare.</h1>
        <h1 className={`text-3xl md:text-5xl lg:text-6xl font-semibold ${isHomePage ? "text-white" : "text-black"}`}>Save.</h1>
      </div>
    </div>
  );
};

export default EventComparisonHeader;
