import React, { useEffect, useState } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function SuspenseLoader({ embedded, size = 64, height = "auto", width = "auto" }: { embedded?: boolean, size?: number, height?: string, width?: string }) {
  // useEffect(() => {
  //   NProgress.start();

  //   return () => {
  //     NProgress.done();
  //   };
  // }, []);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          return 100;
        } else {
          return prevProgress + 1;
        }
      });
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        position: embedded ? 'relative' : 'fixed',
        left: 0,
        top: 0,
        width: width,
        height: embedded ? height : '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
       <CircularProgressWithLabel
        size={size}
        thickness={3}
        value={progress}
      />
    </Box>
  );
}

export default SuspenseLoader;
