import { FC } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

type TitleVariant = "smaller" | "small" | "large" | "largest";
const getHeadVariant = (variant: TitleVariant) => {
  if (variant === "small") {
    return "h3";
  } else if (variant === "smaller") {
    return "h4";
  } else if (variant === "large") {
    return "h2";
  } else {
    return "h1";
  }
};
const getSubHeadVariant = (variant: TitleVariant) => {
  if (variant === "small") {
    return "h4";
  } else if (variant === "smaller") {
    return "h5";
  } else if (variant === "large") {
    return "h3";
  } else {
    return "h2";
  }
};
const PageTitle: FC<{
  children?: React.ReactNode;
  text: string;
  subtitle?: string;
  variant?: TitleVariant;
}> = ({ children, text = "", subtitle, variant = "large" }) => {
  const theme = useTheme() as Theme;
  return (
    <div
      style={{
        //paddingLeft: "16px",
        //borderLeft: `5px solid ${theme.colors.primary.main}`,
        margin: "20px 0px",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "20px", md: "30px" } }}
        variant={getHeadVariant(variant)}
        component={getHeadVariant(variant)}
        color={theme.colors.alpha.black["100"]}
      >
        {text.toUpperCase()}
      </Typography>
      {subtitle !== undefined ? (
        <Typography
          variant={getSubHeadVariant(variant)}
          component={getSubHeadVariant(variant)}
          color={theme.colors.shadows.primary}
          sx={{ fontSize: { md: 30, xs: 20 } }}
        >
          {subtitle}
        </Typography>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};
export default PageTitle;
