import { API_NAMES } from "../../Enums";

export interface IRequest {
  paramsType: string;
  method: string;
  url: string;
  params: Record<string, any>;
}

const extract = (params: any, field: any) => {
  const value = params[field];
  delete params[field];
  return value;
};

export const getRequest = (
  apiName: API_NAMES,
  params: Record<string, any>
): IRequest => {
  switch (apiName) {
    case API_NAMES.APP_PRELOAD:
      // ✅
      return {
        method: "get",
        url: `/api/app_preload`,
        params: params,
      } as IRequest;
    case API_NAMES.PERFORMER:
      // ✅
      return {
        method: "get",
        url: `/api/sports/performers/${extract(params, "performerId")}`,
        params: params,
      } as IRequest;
    case API_NAMES.SPORT:
      // ✅
      return {
        method: "get",
        url: `/api/sports/${extract(params, "sportCode")}`,
        params: params,
      } as IRequest;
    case API_NAMES.EVENT:
      // ✅
      return {
        method: "get",
        url: `/api/sports/events/${extract(params, "eventId")}`,
        params: params,
      } as IRequest;
    case API_NAMES.BLOGS:
      return {
        method: "get",
        url: `/api/blogs/meta`,
        params: params,
      } as IRequest;
    case API_NAMES.BLOG:
      return {
        method: "get",
        url: `/api/blogs/${extract(params, "blogCode")}`,
        params: params,
      } as IRequest;
    case API_NAMES.SEARCH:
      // ✅
      return {
        method: "get",
        url: `/api/search/event`,
        params: params,
      } as IRequest;
    case API_NAMES.TOP_EVENTS:
      return {
        method: "get",
        url: `/api/top/event`,
        params: params,
      } as IRequest;
    default:
      throw new Error(`unknown api name ${apiName}`);
  }
};
