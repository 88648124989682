import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        text-decoration: none;
        display: contents;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const AppLogo = styled('img')(
  ({ theme }) => ({
    width: "180px"
  })

);


function Logo() {

  return (
    <LogoWrapper to="/">
      <AppLogo src="/static/images/logo_full.svg" />
      {/* </Badge> */}
    </LogoWrapper>
  );
}

export default Logo;
