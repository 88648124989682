import { useContext, useEffect, useRef, useState } from 'react';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AvatarIcon from '@mui/icons-material/AccountCircleTwoTone';
import ActionButton from "../../../components/ActionButton";

import {
  Box,
  alpha,
  IconButton,
  lighten,
  Button,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useCookies } from 'react-cookie';
import { SidebarContext } from '../../../contexts/SidebarContext';
import { Theme, useTheme, styled } from '@mui/material/styles';
import Logo from '../../../components/LogoSign';
import HeaderMenu from './HeaderMenu';
import MuliOptionsMenu from './MuliOptionsMenu';
import { Link, useLocation } from 'react-router-dom';
import SearchBox from '../../../content/pages/home/SearchBox';
import LoginDialog from './LoginDialog';
import { DialogContext } from '../../../contexts/DialogContext';
import axios from '../../../api/axios';
import RegisterModal from './RegisterModal';
import AddOrderDialog from '../../../content/pages/cashback/AddOrderDialog';
import ViewOrdersDialog from '../../../content/pages/cashback/ViewOrdersDialog';
import WithdrawDialog from '../../../content/pages/cashback/WithdrawDialog';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import SettingsDialog from '../../../content/pages/home/SettingsDialog';
const HeaderWrapper = styled(Box)(
  ({ theme }: { theme: any }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme() as Theme;
  const dialogs = useContext(DialogContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();

  const [balance, setBalance] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    // checkAuth();
    // getBalance();
  }, [])

  async function checkAuth() {
    try {
      const token = localStorage.getItem("token") || cookies["token"] || "";
      const response = await axios.get("/api/users/auth", {
        headers: {
          token,
        }
      });
      if(response.status === 200) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function getBalance() {
    try {
      const response = await axios.get("/api/users/balance");
      if(response.status === 200) {
        setBalance(response.data.balance);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleViewOrders() {
    dialogs.openDialog("cashbackViewOrders");
  }

  function handleAddOrder() {
    dialogs.openDialog("cashbackAddOrder");
  }

  function handleWithdraw() {
    dialogs.openDialog("cashbackWithdraw");
  }

  function handleSettings() {
    dialogs.openDialog("settings");
  }

  async function handleSignOut() {
    await signOut(getAuth());
    localStorage.removeItem("token");
    removeCookie("token");
    setIsLoggedIn(false);
  }
  

  const sports = window.app_preload.sports;
  const sportPerformerEntities = window.app_preload.sportPerformerEntities as Record<string, unknown>[];
  const performersGrouped = sportPerformerEntities.filter((e) => e.role !== null).reduce((acc: Map<string, any[]>, e: any) => {
    const performers = acc.get(e.sportEntity.code);
    if (performers !== undefined) {
      performers.push(e);
    } else {
      acc.set(e.sportEntity.code, [e]);
    }
    return acc;
  }, new Map());
  const sportMenuOptions = sports.map((sport: any) => {
    const performers = performersGrouped.get(sport.code) || []
    return {
      id: sport.code,
      name: sport.name,
      to: `/sports/${sport.code}`,
      links: performers.map((performer: any) => {
        return {
          id: performer.id,
          name: performer.name,
          href: `/performers/${performer.id}`,
        }
      }).sort((a: any, b: any) => a.name.localeCompare(b.name, "en", { sensitivity: "base" }))
    }
  });
  
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      zIndex={"100 !important"}
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.7),
              0.15
            )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
              theme.colors.alpha.black[100],
              0.2
            )}, 0px 5px 22px -4px ${alpha(
              theme.colors.alpha.black[100],
              0.1
            )}`
      }}
    >

      {/* Dialogs */}
      <LoginDialog onClose={(succeeded) => setIsLoggedIn(succeeded)} />
      <RegisterModal onClose={(succeeded) => setIsLoggedIn(succeeded)} />
      <ForgotPasswordDialog />
      <ViewOrdersDialog />
      <AddOrderDialog />
      <WithdrawDialog />
      <SettingsDialog />

      <Box
        display="flex"
        alignItems="center"
        sx={{
          margin: 'auto',
          maxWidth: theme.page.containerWidth,
          width: "100%",
        }}
      >
        <Logo />
        <Box
          display="flex"
          alignItems="center"
          sx={{
            margin: 'auto',
            maxWidth: theme.page.containerWidth,
            width: "100%",
            display: { md: 'flex', xs: 'none' }
          }}
        >
          {
            location.pathname !== "/" &&
            <Box marginLeft={theme.spacing(2)} width="100%">
              <SearchBox />
            </Box>
          }
          {/* <HeaderMenu /> */}
          <span style={{ marginLeft: 'auto' }}>
            {location.pathname === '/' ? (
              <ActionButton
                text="About Us"
                sx={{ width: '100%', maxWidth: '95vw' }}
                href="/about"
              />
            ) : (
              <MuliOptionsMenu text="SPORTS" options={sportMenuOptions} />
            )}
          </span>
          
          {
            /* isLoggedIn ?
            <>
              <Avatar ref={menuRef} onClick={toggleMenu}><AvatarIcon /></Avatar>
              <Menu open={isMenuOpen} onClose={toggleMenu} anchorEl={menuRef.current}>
                <Typography variant='body2' style={{ padding: '0px 10px' }}><span style={{ fontSize: '1.25em', color: '#e6ae08' }}>Balance:</span> {balance} Points</Typography>
                <hr style={{ width: '90%' }} />
                <MenuItem onClick={handleViewOrders}><Typography variant='body2'>View Orders</Typography></MenuItem>
                <MenuItem onClick={handleAddOrder}><Typography variant='body2'>Add Order</Typography></MenuItem>
                <MenuItem onClick={handleWithdraw}><Typography variant='body2'>Withdraw</Typography></MenuItem>
                <MenuItem onClick={handleSettings}><Typography variant='body2'>Settings</Typography></MenuItem>
                <MenuItem onClick={handleSignOut}><Typography variant='body2'>Sign Out</Typography></MenuItem>
              </Menu>
            </>
            :
            <>
              <Button variant='text' sx={{ width: 100, }} onClick={() => dialogs.openDialog("login")}><Typography variant='body2'>LOG IN</Typography></Button>
              <Button variant='text' sx={{ width: 100, }} onClick={() => dialogs.openDialog("register")}><Typography variant='body2'>REGISTER</Typography></Button>
            </> */
          }
        </Box>
        <Box
          component="span"
          sx={{
            ml: 2,
            marginLeft: "auto",
            display: { md: 'none', xs: 'inline-block' }
          }}
        >
          <IconButton color="primary" onClick={toggleSidebar} sx={{
            borderRadius: '50%',
            border: '1px solid rgba(0, 0, 0, 0.2)'
          }}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize="medium" sx={{ color: 'black'}} />
            ) : (
              <CloseTwoToneIcon fontSize="medium" sx={{ color: 'black'}} />
            )}
          </IconButton>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
