import React from 'react'
import SeatMap from '../../../components/SeatMap'

const EVENT_ID = '5173862'

const SeatMapPage = () => {


  return (
    <div>
      <SeatMap eventId={EVENT_ID} />
    </div>
  )
}

export default SeatMapPage