import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../api/axios";
import { getRequest, IRequest } from "./mapper/RequestMapper";
import { API_NAMES } from "../Enums";
import qs from "qs";

export const callAPI = async (
  apiName: API_NAMES,
  params: Record<string, any>
): Promise<AxiosResponse> => {
  const request: IRequest = getRequest(apiName, params);
  const method = request.method;
  const paramsType = request.paramsType || "query";
  const url = request.url;

  let axiosOptions: AxiosRequestConfig;

  if (paramsType === "query") {
    axiosOptions = {
      method: request.method,
      url: url,
      params: request.params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    };
  } else {
    axiosOptions = {
      method: method,
      url: url,
      data: request.params,
    };
  }
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 403 || err.response?.status === 401) {
      if (!window.location.pathname.includes("/login"))
        window.location.href = "/login";
    }
    throw error;
  }
};
