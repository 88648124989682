import SuspenseLoader from "../../../components/SuspenseLoader";
import { useNavigate, useParams } from "react-router-dom";
import APIFetchContainer, { VisualComponentProps } from "../../../containers/APIFetchContainer";
import { API_NAMES } from "../../../Enums";
import {
  Grid, Theme, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BgHeader from "../../../layouts/BgHeader";
import PageContentWrapper from "../../../layouts/PageContentWrapper";
import { useEffect, useRef } from "react";
import PageTitle from "../../../components/page/PageTitle";
import { Stack } from "@mui/system";

function hasAllCaps(str: string) {
  const split = str.split(" ");
  for (let index = 0; index < split.length; index++) {
    const element = split[index];
    if (element === element.toUpperCase()) {
      return true;
    } 
  }
  return false;
}

const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({ resultData, extra }) => {
  if (resultData) {
    const theme = useTheme() as Theme;
    const navigate = useNavigate();
    const sportEntity = resultData.sportEntity;
    const sportName = sportEntity.name?.toUpperCase();
    const onlyTeams = ["NBA", "NBA + G LEAGUE", "NFL", "NCAA BB", "NCAA FB", "MLB", "NHL"].includes(sportName);
    let performers = resultData.performers;
    if(onlyTeams) {
      performers = performers.filter((performer: any) => performer.role !== null);
      // console.log(performers.map((performer: any) => ({id: performer.id, name: performer.name})));
      // performers = performers.filter((performer: any) => (performer.name.indexOf(" vs ") === -1) && !hasAllCaps(performer.name));
    }
    return <>
      <BgHeader>
        <Stack direction={"row"} spacing={theme.spacing(4)} sx={{ alignItems: "center" }}>
          <div style={{
            height: "150px",
            width: "150px",
            background: `url("${sportEntity.logo}")`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}>
          </div>

          <div>
            <Typography
              variant="h1"
              color={theme.colors.alpha.white["100"]}
              sx={{ fontSize: { md: 100, xs: 40 }, fontStyle: "italic" }}
              textAlign={"center"}>
              {sportEntity.name?.toUpperCase()}
            </Typography>

          </div>
        </Stack>
      </BgHeader>
      <PageContentWrapper>
        <PageTitle text={onlyTeams? "All Teams" : "All Games And Teams"} variant={"large"} />
        <Grid container spacing={2}>
          {
            (performers as Record<string, any>[]).sort((a, b) => (a.name).localeCompare(b.name)).map((performer: any) => {
              return <Grid item key={performer.id} xs={6} sm={4} md={3}>
                <Card style={{ height: "100%" }}>
                  <CardActionArea style={{ display: "flex", flexDirection: "column", height: "100%" }} onClick={() => {
                    navigate(`/performers/${performer.id}`)
                  }}>
                    <CardContent style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1", width: "100%" }}>
                      <Typography gutterBottom variant="h4" component="div">
                        {performer.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {performer.numberOfEvents} Events +
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            })
          }
        </Grid>
      </PageContentWrapper>
    </>
  } else {
    return <SuspenseLoader embedded={true} />
  }
};
const SportPage = () => {
  const { sportCode } = useParams();
  const ref = useRef();
  useEffect(() => {
    if (ref.current !== undefined) {
      const s = ref.current as any;
      s.onRequestDataChange({ sportCode: sportCode });
    }
  }, [sportCode]);
  return <APIFetchContainer ref={ref} apiName={API_NAMES.SPORT} needDataFetch={true} VisualComponent={VisualComponent} requestData={{ sportCode: sportCode }} />

}
export default SportPage;