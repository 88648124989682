import React, { useContext, useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, Grid, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DialogContext } from '../../../contexts/DialogContext';
import axios from '../../../api/axios';

function AddOrderDialog() {

  const dialogContext = useContext(DialogContext);

  const [orderNumber, setOrderNumber] = useState("");
  const [orderProvider, setOrderProvider] = useState("");
  const [error, setError] = useState("");

  async function handleSubmit() {
    try {
      await axios.post("/api/cashback/requests", {
        orderId: orderNumber,
        provider: orderProvider,
      });
      dialogContext.setMessage("Order registration recorded. Balance will be updated after verification!");
      closeDialog();
    } catch (err: any) {
      console.error(err)
      setError(err.response.data.error);
    }
  }
  
  function closeDialog() {
    if(dialogContext.cashbackAddOrder) {
      dialogContext.closeDialog();
    }
    setTimeout(() => {
      setOrderNumber("");
      setOrderProvider("");
      setError("");
    }, 100);
  }

  return (
    <Dialog open={dialogContext.cashbackAddOrder}>
      <DialogContent>
        <Grid container direction='column' spacing={'4px'}>
          <Grid item>
            <Typography variant='h3'>Add Order</Typography>
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={'8px'}>
              {
                error && (
                  <Grid item>
                    <Typography variant='body1' color='error'>{error}</Typography>
                  </Grid>
                )
              }
              <Grid item>
                <TextField
                  label='Order Number'
                  variant='outlined'
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel id='order-provider-label'>Order Provider</InputLabel>
                  <Select value={orderProvider} label="Order Provider" labelId='order-provider-label' onChange={(e) => setOrderProvider(e.target.value)}>
                    <MenuItem disabled value="">Select Provider</MenuItem>
                    {/* <MenuItem value="ticketmaster">TicketMaster</MenuItem> */}
                    <MenuItem value="stubhub">StubHub</MenuItem>
                    <MenuItem value="viagogo">Viagogo</MenuItem>
                    {/* <MenuItem value="seatgeek">SeatGeek</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={closeDialog}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddOrderDialog