import { AccessTimeRounded } from '@mui/icons-material';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import PageTitle from '../../../components/page/PageTitle';
import SuspenseLoader from '../../../components/SuspenseLoader';
import APIFetchContainer, { VisualComponentProps } from '../../../containers/APIFetchContainer';
import { API_NAMES } from '../../../Enums';
import BgHeader from '../../../layouts/BgHeader';
import PageContentWrapper from '../../../layouts/PageContentWrapper';
import { formatToLocal } from '../../../utils/DateUtils';



const BlogPost = ({ image, file, title, snippet, date }: { image: string, title: string, snippet: string, file: string, date: string }) => {
  const navigate = useNavigate();
  const theme = useTheme() as Theme;
  return <Card style={{}} >
    <CardMedia
      sx={{ height: 240 }}
      image={`static/images/blogs/${image}`}
    />
    <CardContent>
      <Typography variant='h3'>
        {title.toUpperCase()}
      </Typography>
      <Typography variant='h5' color={theme.colors.alpha.black[50]} fontWeight={500} component="div" display={"flex"} justifyItems={"center"}>
        <AccessTimeRounded fontSize='small' style={{ marginRight: theme.spacing(0.5) }} />  {formatToLocal(date)}
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {snippet}
      </Typography>
      <ActionButton text='read more' onClick={() => navigate(file)} />
    </CardContent>
  </Card>
}

const BlogsContentPage = ({ posts }: { posts: any[] }) => {
  const theme = useTheme() as Theme;
  const navigate = useNavigate()
  const headBlog = posts.shift();
  return <PageContentWrapper>
    <PageTitle text='Latest' />
    <div style={{
      background: `url("static/images/blogs/${headBlog.image}")`,
      width: "100%",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }} >
      <Box sx={{
        width: { xs: '100%', md: '40%' },
        background: "linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 83%, rgba(0,0,0,0) 100%);",
        padding: theme.spacing(4)
      }}>
        <Stack spacing={4}>
          <Typography variant="h2" component="h2" color={theme.colors.alpha.white["100"]}>
            {headBlog.title.toUpperCase()}
          </Typography>
          <Typography variant="h4" component="span" color={theme.colors.alpha.white["100"]} fontWeight={400}>
            {headBlog.snippet.toUpperCase()}
          </Typography>
          <ActionButton text='read more' onClick={() => navigate(`/blogs/${headBlog.file}`)} sx={{ marginTop: theme.spacing(5), width: "fit-content" }} />
        </Stack>
      </Box>
    </div>
    <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }}>
      {
        posts.map((post: any) => {
          return <Grid key={post.file} item sm={4}>
            <BlogPost {...post} />
          </Grid>;
        })
      }
    </Grid>
  </PageContentWrapper>
}
const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({ resultData, extra }) => {
  if (resultData) {
    return <BlogsContentPage posts={resultData} />;
  } else {
    return <SuspenseLoader embedded={true} />
  }
};

const BlogsPage = () => {
  const theme = useTheme() as Theme;
  return <div>
    <BgHeader title='POPULAR BLOGS' />
    <PageContentWrapper>
      <APIFetchContainer apiName={API_NAMES.BLOGS} needDataFetch={true} VisualComponent={VisualComponent} />
    </PageContentWrapper>
  </div>
}
export default BlogsPage;