import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
const MenuItemWrapper = styled(Link)(
    ({ theme }) => `
          text-decoration: none;
          color: ${theme.colors.alpha.white[100]};
          margin: 10px 30px
  `
);

interface MenuItemProps {
    text: string;
    href: string;
}
const MenuItem: FC<MenuItemProps> = ({ text, href }) => {
    return (
        <MenuItemWrapper to={href}>
            <Typography component={'span'} variant='body2'>{text}</Typography>
        </MenuItemWrapper>
    );
};

export default MenuItem;
