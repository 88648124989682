import { FC, ReactNode, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
    Popper, Fade, Paper, Grid, Typography
} from '@mui/material';
import { Link } from 'react-router-dom';

const MenuTitleWrapper = styled('span')(
    ({ theme }) => `
          cursor:default;
          color: ${theme.colors.alpha.black[70]};
          margin: 10px 30px
  `
);

const MenuItemWrapper = styled('span')(
    ({ theme }) => `
        width: 100%;
        border-radius: 10px;
        display: block;
        cursor:default;
        padding: 8px 15px;
        color: ${theme.colors.alpha.black[70]};
  `
);

const MenuLinkWrapper = styled(Link)(
    ({ theme }) => `
        text-decoration: none;
        width: 100%;
        border-radius: 10px;
        &:hover {
            color: ${theme.colors.alpha.black[100]};
        }
        color: ${theme.colors.alpha.black[70]};
  `
);
const SubMenuLinkWrapper = styled(Link)(
    ({ theme }) => `
        text-decoration: none;
        width: 100%;
        border-radius: 10px;
        display: block;
        padding: 8px 15px;
        &:hover {
            color: ${theme.colors.alpha.black[100]};
        }
        color: ${theme.colors.alpha.black[70]};
  `
);

interface MuliOptionsMenuProps {
    text: string;
    options: any
}
const MuliOptionsMenu: FC<MuliOptionsMenuProps> = ({ options, text }) => {
    const [selectedOption, setSelectedOption] = useState(options[0])
    const [openedPopover, setOpenedPopover] = useState(false)
    const [onMenu, setOnMenu] = useState(false)
    const popoverAnchor = useRef(null);

    const popoverEnter = () => {
        setOpenedPopover(true)
    };

    const popoverLeave = () => {
        setOpenedPopover(false)
    };


    function truncate(str: string) {
        return str.length > 30 ? str.substring(0, 20) + "..." : str;
    }

    return (
        <MenuTitleWrapper>
            <span
                ref={popoverAnchor}
                aria-owns="mouse-over-popover"
                aria-haspopup="true"
                onMouseEnter={popoverEnter}
                onMouseLeave={() => {
                    popoverLeave()
                }}
            >
                <Typography component={'span'} variant='body2'>{text}</Typography>
            </span>

            <Popper open={openedPopover || onMenu} anchorEl={popoverAnchor.current} placement="bottom-start" sx={{ zIndex: 200 }} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350} onMouseEnter={() => {
                        setOnMenu(true)
                    }} onMouseLeave={() => {
                        setOnMenu(false)
                    }}>
                        <Paper sx={{ marginTop: "10px", width: 600, height: 600, maxHeight: 600 }}>
                            <Grid container width={600}>
                                <Grid item xs={4} sx={{ backgroundColor: "#f6f6f6", padding: "20px" }}>
                                    {options.map((option: any) => {
                                        return <MenuItemWrapper
                                            key={option.id}
                                            style={{ backgroundColor: selectedOption.id === option.id ? "#e7e7e7" : "transparent" }}
                                            onClick={() => {
                                                setOnMenu(false)
                                            }}
                                            onMouseEnter={() => {
                                                setSelectedOption(option);
                                            }}>
                                            {option.to ? <MenuLinkWrapper to={option.to}>{option.name}</MenuLinkWrapper> : option.name}
                                        </MenuItemWrapper>
                                    })}
                                </Grid>
                                <Grid item xs={8} sx={{ backgroundColor: "#fcfcfc", padding: "20px", height: 600, overflowY: "auto" }}>
                                    {selectedOption.links.map((link: any) => {
                                        return <SubMenuLinkWrapper
                                            to={link.href}
                                            key={link.id}
                                            onClick={() => {
                                                setOnMenu(false)
                                            }}
                                        >
                                            {truncate(link.name)}
                                        </SubMenuLinkWrapper>
                                    })}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </MenuTitleWrapper>
    );
};

export default MuliOptionsMenu;
