import { PropsWithChildren, createContext, useState } from "react";

const initialState = {
  login: false,
  register: false,
  forgotPassword: false,
  settings: false,
  cashbackAddOrder: false,
  cashbackViewOrders: false,
  cashbackWithdraw: false,
  error: "",
  message: "",
}

export const DialogContext = createContext({
  ...initialState,
  openDialog: (dialog: string) => {console.log("openDialog not implemented", dialog)},
  closeDialog: () => {console.log("closeDialog not implemented")},
  setError: (error: string) => {console.log("setError not implemented", error)},
  setMessage: (message: string) => {console.log("setMessage not implemented", message)},
});

export function DialogContextProvider(props: PropsWithChildren) {

  const [dialogs, setDialogs] = useState(initialState)

  function openDialog(dialog: string) {
    switch (dialog) {
      case "login":
        setDialogs({ ...initialState, login: true });
        break;
      case "register":
        setDialogs({ ...initialState, register: true });
        break;
      case "forgotPassword":
        setDialogs({ ...initialState, forgotPassword: true });
        break;
      case "settings":
        setDialogs({ ...initialState, settings: true });
        break;
      case "cashbackAddOrder":
        setDialogs({ ...initialState, cashbackAddOrder: true });
        break;
      case "cashbackViewOrders":
        setDialogs({ ...initialState, cashbackViewOrders: true });
        break;
      case "cashbackWithdraw":
        setDialogs({ ...initialState, cashbackWithdraw: true });
        break;
      default:
        console.log("Invalid dialog:", dialog);
        break;
    }
  }

  function closeDialog() {
    setDialogs(initialState);
  }

  function setError(error: string) {
    setDialogs({ ...dialogs, error });
    setTimeout(() => {
      setDialogs({ ...dialogs, error: "" });
    }, 5000);
  }

  function setMessage(message: string) {
    setDialogs({ ...dialogs, message });
    setTimeout(() => {
      setDialogs({ ...dialogs, message: "" });
    }, 5000);
  }

  return (
    <DialogContext.Provider value={{ ...dialogs, openDialog, closeDialog, setError, setMessage }}>
      {props.children}
    </DialogContext.Provider>
  )
}

