import React, { useState } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './base';
;
export const ThemeContext = React.createContext(
  (themeName: string): void => {
    throw Error(`set theme is not implemented ${themeName}]`);
  }
);


const ThemeProviderWrapper: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const curThemeName = 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
