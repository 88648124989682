import React, { useEffect } from "react";
import axios from "../api/axios";
import { useQuery } from "@tanstack/react-query";

const fetchSeatMapCode = async (eventId: string) => {
  const res = await axios.get(`/api/seatmap/${eventId}`);

  return res.data;
};

const SeatMap = (props: { eventId: string }) => {
  const { eventId } = props;
  const { data, isLoading } = useQuery({
    queryKey: ["seatmap", { eventId }],
    queryFn: () => fetchSeatMapCode(eventId),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      const script = document.createElement('script');
      console.log("called")
      
      script.appendChild(document.createTextNode(data));
      // script.src = `http://localhost:3000/api/seatmap/${eventId}`;
      // script.async = true;
    
      document.head.appendChild(script);
    
    }

    // return () => {
    //   document.body.removeChild(script);
    // }
  }, [data]);

  return <div>SeatMap</div>;
};

export default SeatMap;
