import { Button, Dialog, DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DialogContext } from '../../../contexts/DialogContext';
import axios from '../../../api/axios';

type Order = {
  id: string,
  provider: string,
  orderId: string,
  status: string,
}

function ViewOrdersDialog() {

  const [orders, setOrders] = useState<Order[]>([]);
  const [page, setPage] = useState(1);

  const dialogContext = useContext(DialogContext);

  useEffect(() => {
    if(!dialogContext.cashbackViewOrders) return;
    getOrders();
  }, [page, dialogContext.cashbackViewOrders])

  async function getOrders() {
    try {
      const response = await axios.get("/api/cashback/requests", {
        params: {
          page,
        }
      });
      setOrders(response.data.requests);
    } catch (err) {
      console.warn(err);
    }
  }

  function closeDialog() {
    if(dialogContext.cashbackViewOrders) {
      dialogContext.closeDialog();
    }
  }

  return (
    <Dialog open={dialogContext.cashbackViewOrders}>
      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container direction='column' spacing={'4px'}>
          <Grid item>
            <Typography variant='h3'>List Order</Typography>
          </Grid>
          <Grid item style={{ padding: '10px 4px', borderRadius:8, }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Provider</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.provider.toUpperCase()}</TableCell>
                      <TableCell>{order.orderId}</TableCell>
                      <TableCell>{order.status.toUpperCase()}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={closeDialog}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewOrdersDialog