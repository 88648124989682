import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axios.interceptors.request.use((config) => {
  if(config.headers === undefined) {
    config.headers = {};
  }
  config.headers.token = localStorage.getItem("token") || "";
  return config;
});

export default axios;