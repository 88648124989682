import SuspenseLoader from "../../../components/SuspenseLoader";
import { useParams } from "react-router-dom";
import APIFetchContainer, {
  VisualComponentProps,
} from "../../../containers/APIFetchContainer";
import { API_NAMES } from "../../../Enums";
import { Theme, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BgHeader from "../../../layouts/BgHeader";
import PageContentWrapper from "../../../layouts/PageContentWrapper";
import { useEffect, useRef, useState } from "react";
import ActionButton from "../../../components/ActionButton";
import { formatToLocal, dayjs, TIME_ZONE } from "../../../utils/DateUtils";
import MoreAction from "../../../components/MoreAction";
import PageTitle from "../../../components/page/PageTitle";
import { Dayjs } from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";

interface Performer {
  score: number;
  performerName: string;
  performerId: string;
  imageEvent: string | null;
}

interface PerformersData {
  matchedPerformers: Performer[];
  matchedSports?: any[]; 
}

const Container: React.FunctionComponent<{ data: any; performer: any }> = ({
  data,
  performer,
}) => {
  console.log("[PERFORMER] DATA",{data})
  console.log("[PERFORMER] DATA",{performer})
  const theme = useTheme() as Theme;
  const listSizeIncrement = 10;
  const [listSize, setListSize] = useState(10);
  const [loadMore, setLoadMore] = useState(false);
  const [applyingFilters, setApplyingFilters] = useState(false);
  const minDate = dayjs.tz(dayjs(data[0]?.date));
  const maxDate = dayjs.tz(data[data.length - 1]?.date);
  const [dateFilter, setDateFilter] = useState<Dayjs | null>(null);
  console.log({ data, dateFilter: dateFilter?.utc() });
  const applyFilter = () => {
    setApplyingFilters(true);
    setTimeout(() => {
      setApplyingFilters(false);
    }, 1000);
  };
  if (data && data.length > 0) {
    const finalEvents = data.filter((event: any) => {
      return (
        dateFilter === null ||
        dayjs(event.date).isSame(dayjs(dateFilter?.format("YYYY-MM-DD")), "day")
      );
    });
    return (
      <div>
        <Grid container>
          <Grid item xs={12} md={7}>
            <PageTitle text={performer.name + " SCHEDULE"} variant={"large"} />
          </Grid>
          <Grid
            display={"flex"}
            item
            xs={12}
            md={5}
            alignItems={"center"}
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Stack direction={"row"} spacing={theme.spacing(2)}>
              <DesktopDatePicker
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={(newValue: any) => {
                  setDateFilter(newValue);
                  applyFilter();
                }}
                minDate={minDate.utc()}
                maxDate={maxDate.utc()}
              />
            </Stack>
          </Grid>
        </Grid>
        {applyingFilters ? (
          <SuspenseLoader embedded={true} size={30} height={"200px"} />
        ) : (
          <Grid container>
            {finalEvents.slice(0, listSize).map((d: any) => {
              return (
                <Grid
                  key={d.id}
                  container
                  marginY={theme.spacing(4)}
                  spacing={theme.spacing(1)}
                >
                  <Grid item xs={3} sm={2} sx={{ whiteSpace: "nowrap" }}>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: 18,
                        color: theme.colors.primary.main,
                      }}
                    >
                      {formatToLocal(d.date, "MMMM D")}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{ fontSize: 16 }}
                    >
                      {formatToLocal(d.date, "ddd, h:mm A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={8}>
                    <div style={{ paddingLeft: theme.spacing(4) }}>
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ fontWeight: 500, fontSize: 18 }}
                      >
                        {d.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ fontSize: 16 }}
                      >
                        {d.venue ? d.venue?.name + " - " : ""} {d.location}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ justifyContent: { xs: "center", sm: "right" } }}
                    display={"flex"}
                  >
                    {/* <ActionButton text={'Buy Tickets'} sx={{ width: "120px" }} href={`/performers/${performer.id}/events/${d.id}#performerId=${performer.id}`} /> */}
                    <ActionButton
                      text={"Compare Prices"}
                      sx={{ width: "100%", maxWidth: "95vw" }}
                      href={`/events/${d.id}`}
                    />
                  </Grid>
                </Grid>
              );
            })}
            <div>
              {loadMore ? (
                <SuspenseLoader embedded={true} size={30} />
              ) : (
                <div>
                  {listSize < finalEvents.length && (
                    <MoreAction
                      text={"View more"}
                      action={() => {
                        setLoadMore(true);
                        setTimeout(() => {
                          setLoadMore(false);
                          setListSize(listSize + listSizeIncrement);
                        }, 1000);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </Grid>
        )}
      </div>
    );
  } else {
    return (
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontSize: { md: 30, xs: 20 } }}
        textAlign={"center"}
      >
        No upcoming events
      </Typography>
    );
  }
};

const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({
  resultData,
  extra,
}) => {
  const [performersObject, setPerformersObject] = useState<PerformersData | null>(null);
  const query = resultData?.performer?.name;

  useEffect(() => {
    const fetchPerformersData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/search/event`,
          {
            params: { q: query },
          }
        );
        setPerformersObject(response.data);
      } catch (error) {
        console.error("Error fetching performers data:", error);
      }
    };

    if (query) {
      fetchPerformersData();
    }
  }, [query]);

  const imageEvent = performersObject?.matchedPerformers[0]?.imageEvent;

  const formattedImageEvent = imageEvent
  ? `https://media.stubhubstatic.com/stubhub-v2-catalog/t_f-fs-0fv,q_auto:low,f_auto,c_fill,$w_280_mul_3,$h_180_mul_3/categories/${
      imageEvent.split("categories/")[1]
    }`
  : null;

  console.log('[PERFORMERS] performersObject',{performersObject})
  // Make a call to "api/search/event" and store the data in a variable called performersObject
  if (resultData) {
    console.log('[PERFORMER] resultData', {resultData})
    const performer = resultData.performer;
    let img = formattedImageEvent || performer.image?.large || performer.image;
    if (resultData.performer.type === "golf") {
      img = "/static/images/backgrounds/golf.jpeg";
    } else if (resultData.performer.type === "nascar") {
      img = "/static/images/backgrounds/nascar.jpeg";
    }
    console.log('[PERFORMER] img', img)
    return (
      <>
        <BgHeader
          image={img}
          title={performer.image === undefined && performer.shortName}
        />
        <PageContentWrapper>
          <Container data={resultData.events} performer={performer} />
        </PageContentWrapper>
      </>
    );
  } else {
    return <SuspenseLoader embedded={true} height="500px" />;
  }
};
const Events = () => {
  const { performerId } = useParams();
  const ref = useRef();
  useEffect(() => {
    if (ref.current !== undefined) {
      const s = ref.current as any;
      s.onRequestDataChange({ performerId: performerId });
    }
  }, [performerId]);
  return (
    <div>
      <APIFetchContainer
        ref={ref}
        apiName={API_NAMES.PERFORMER}
        needDataFetch={true}
        VisualComponent={VisualComponent}
        requestData={{ performerId: performerId }}
      />
    </div>
  );
};
export default Events;
