import { styled, Button, ButtonProps, SxProps } from "@mui/material";
import { FC } from "react"
import { useNavigate } from "react-router-dom";




const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.colors.alpha.white[100],
    fontWeight: 500,
    backgroundColor: theme.colors.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const ActionButton: FC<{ text: string, sx?: SxProps, onClick?: () => void, disabled?: boolean, href?: string }> = ({ text, sx, onClick, disabled, href }) => {
    const navigate = useNavigate();
    return <CustomButton
        disabled={disabled}
        sx={{ borderRadius: "30px", width: "fit-content", height: "fit-content", ...sx }}
        onClick={onClick ?? (() => {
        if (href !== undefined) {
            navigate(href);
        }})}
    >
        {text}
    </CustomButton>
}

export default ActionButton